exports.onRouteUpdate = () => {
  const elms = document.getElementsByClassName( 'ryuseilight-wrapper' );

  for ( let i = 0; i < elms.length; i++ ) {
    const elm    = elms[ i ];
    const button = elm.querySelector( '.rl__copy' );

    if ( button ) {
      init( elm, button );
    }
  }
};

function init( elm, button ) {
  const pre = elm.querySelector( '.ryuseilight-raw' );

  if ( pre ) {
    button.addEventListener( 'click', () => {
      copy( pre.innerText, button, 1000 );
    } );
  }
}

function copy( code, button, duration ) {
  const onSuccess = () => {
    if ( duration ) {
      toggleClass( button, duration );
    }
  };

  if ( navigator.clipboard ) {
    navigator.clipboard.writeText( code )
      .then( onSuccess )
      .catch( () => execCopy( code, onSuccess ) );
  } else {
    execCopy( code, onSuccess );
  }
}

function execCopy( code, onSuccess ) {
  const textarea = document.createElement( 'textarea' );
  textarea.textContent = code;

  textarea.style.position = 'absolute';
  textarea.style.left     = '-99999px';

  document.body.appendChild( textarea );

  textarea.focus();
  textarea.select();

  let failed;

  try {
    document.execCommand( 'copy' );
  } catch ( e ) {
    alert( 'Failed to copy.' );
    failed = true;
  }

  document.body.removeChild( textarea );

  if ( ! failed ) {
    onSuccess();
  }
}

function toggleClass( button, duration ) {
  button.classList.add( 'is-active' );

  const prop = '_rlTimer';

  if ( button[ prop ] ) {
    clearTimeout( button[ prop ] );
  }

  button[ prop ] = setTimeout( () => {
    button.classList.remove( 'is-active' );
  }, duration );
}
